<template>
  <ListCommission />
</template>

<script>
import ListCommission from '@/components/ListCommission'

export default {
  components: {
    ListCommission,
  },
}
</script>
