<template>
  <div>
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col-lg-12">
            <div style="width: 100%" v-if="isSearch">
              <a-tabs>
                <a-tab-pane :tab="$t('sales.searchResult')" />
                <div
                  slot="tabBarExtraContent"
                  :style="{ cursor: 'pointer' }"
                  @click="handleCloseTabSearch"
                >
                  <a-icon type="close" />
                </div>
              </a-tabs>
            </div>
            <a-form
              :form="form"
              @submit.prevent="handleSearch"
              v-if="!isSearch"
            >
              <div class="row">
                <div class="col mt-2">
                  <a-form-item class="mb-0">
                    <a-input-search
                      :placeholder="$t('sales.searchCommission')"
                      v-decorator="['search']"
                    />
                  </a-form-item>
                </div>
                <div class="col-md-auto mt-1">
                  <div>
                    <a-form-item class="mb-0">
                      <a-range-picker v-decorator="['validity', rangeConfig]" />
                    </a-form-item>
                  </div>
                </div>
                <div class="col col-lg-1 mt-1">
                  <a-form-item class="mb-0">
                    <a-button type="primary" html-type="submit">
                      {{ $t('sales.search') }}
                    </a-button>
                  </a-form-item>
                </div>
              </div>
            </a-form>
          </div>
        </div>
      </div>
      <div class="card-body">
        <!-- <div v-if="!commission.length && !loading" class="d-flex justify-content-center">
        <div class="text-bold" style="font-size: 30px">
          <strong> Commission not found </strong>
        </div>
      </div> -->
        <a-table
          :loading="loading"
          :columns="columns"
          :data-source="commission"
          :row-key="(data, index) => `${data.order_id}_${index}`"
          :pagination="pagination"
          class="commission-table"
          @change="handleTableChange"
          :scroll="{
            x: 1024
          }"
        >
          <span slot="customTitleCommission">
            <div>{{ $t('sales.commission') }}</div>
            <div class="text-muted" v-if="totalCommission">
              {{ $t('dashboard.totalCommission') }}:
              {{ totalCommission | currency }}
            </div>
          </span>
          <div slot-scope="text, record" slot="commission_date" class="date">
            <div>{{ formateDate(record.commission_date) }}</div>
          </div>
          <div slot="order_id" slot-scope="text, record">
            <strong> {{ record.order_id }} </strong>
          </div>
          <div
            slot="commission_value"
            slot-scope="text, record"
            class="d-flex justify-content-between"
          >
            <div>
              <strong> Rp </strong>
            </div>
            <div>
              <strong> {{ record.commission_value | currency_2 }} </strong>
            </div>
          </div>
          <div
            slot="catalog_title"
            slot-scope="text, record"
            class="product-name"
          >
            <div class="text-left">{{ record.catalog_title }}</div>
          </div>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import moment from 'moment'

export default {
  data: function () {
    return {
      rangeConfig: {
        rules: [
          {
            type: 'array',
            message: 'Please select range date time',
          },
        ],
      },
      test: this.$t('sales.date'),
      form: this.$form.createForm(this),
      isSearch: false,
      page: 1,
      limit: 20,
      columns: [
        {
          title: this.$t('sales.date'),
          dataIndex: 'commission_date',
          key: 'commission_date',
          scopedSlots: { customRender: 'commission_date' },
          class: 'text-center',
          width: 200,
        },
        {
          title: this.$t('sales.orderNumber'),
          dataIndex: 'order_id',
          key: 'order_id',
          scopedSlots: { customRender: 'order_id' },
          class: 'text-center',
        },
        {
          title: this.$t('sales.productName'),
          dataIndex: 'catalog_title',
          key: 'catalog_title',
          scopedSlots: { customRender: 'catalog_title' },
          class: 'text-center',
        },
        {
          // title: this.$t('sales.commission'),
          dataIndex: 'commission_value',
          key: 'commission_value',
          slots: { title: 'customTitleCommission' },
          scopedSlots: { customRender: 'commission_value' },
          class: 'text-center',
        },
      ],
    }
  },
  methods: {
    formateDate(value) {
      return moment(value).format('DD - MMMM - YYYY')
    },
    handleSearch() {
      this.form.validateFieldsAndScroll((err, values) => {
        if (err) return
        else if (!values.search && !values.validity) {
          return this.$notification.error({
            message: this.$t('sales.notifSearch'),
          })
        }
        const { validity, search } = values
        const start_date = validity
          ? moment(validity[0]).format('YYYY-MM-DD hh:mm:ss')
          : ''
        const end_date = validity
          ? moment(validity[1]).format('YYYY-MM-DD hh:mm:ss')
          : ''
        const order_id = search || ''
        this.isSearch = true
        this.$store.dispatch('account/GETCOMMISSIONLIST', {
          start_date,
          end_date,
          order_id,
        })
        this.form.resetFields()
      })
    },
    handleCloseTabSearch() {
      this.isSearch = false
      this.$store.dispatch('account/GETCOMMISSIONLIST', {
        start_date: '',
        end_date: '',
        order_id: '',
      })
    },
    handleTableChange(pagination, filters, sorter) {
      this.$store.dispatch('account/GETCOMMISSIONLIST', {
        start_date: '',
        end_date: '',
        order_id: '',
        page: pagination.current,
        limit: this.limit,
      })
    },
  },
  computed: {
    ...mapState('account', {
      commission: (state) => state.listCommission,
      loading: (state) => state.loading,
      pagination: (state) => state.pagination,
      totalCommission: (state) => state.totalCommission,
    }),
    ...mapState(['settings']),
  },
  beforeCreate() {
    this.$store.dispatch('account/GETCOMMISSIONLIST', {
      start_date: '',
      end_date: '',
    })
  },
  watch: {
    'settings.locale': function () {
      this.columns[0].title = this.$t('sales.date')
      this.columns[1].title = this.$t('sales.orderNumber')
      this.columns[2].title = this.$t('sales.productName')
      this.columns[3].title = this.$t('sales.commission')
    },
  },
}
</script>

<style lang="scss">
// .commission-table {
//   .date {
//     text-align: left !important;
//   }
//   .product-name {
//     text-align: left !important;
//     padding-left: 5rem;
//   }
// }
</style>
